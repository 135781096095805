import React, { useState, useEffect, useMemo } from 'react';
import '../styles/SideNavi.scss';
import LogoText from '../icons/logo-text.png';
import { Link, useNavigate } from 'react-router-dom';
import { useSWRState } from '../fetcher/useSWRState';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useMediaQuery } from '../hooks/useMediaQuery';
import HomeLine from '../icons/home-line.png';
import HomeBold from '../icons/home-bold.png';
import ListLine from '../icons/list-line.png';
import ListBold from '../icons/list-bold.png';

const SideNavi = () => {
  const navigate = useNavigate();
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const [menuVisibleState, setMenuVisibleState] = useState(false);
  const isPageWide = useMediaQuery('(min-width: 916px)');
  const handleMenuHide = () => {
    if (!isPageWide) {
      const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
      menuBar.style.display = 'none';
    }
  };

  // useEffect(() => {
  //   const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
  //   if (isPageWide) {
  //     menuBar.style.display = 'block';
  //   } else {
  //     menuBar.style.display = 'none';
  //   }
  // }, [isPageWide]);

  const logout = () => {
    sessionStorage.clear();
    setSwrState({ ...swrState, user: null });
    handleMenuHide();
    navigate('/');
  };

  const handleMenuVisible = () => {
    const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
    if (menuVisibleState) {
      menuBar.style.display = 'none'; setMenuVisibleState(false);
    } else {
      menuBar.style.display = 'block'; setMenuVisibleState(true);
    }
  };

  const iconSeeds = [
    { title: 'home', icon: swrState?.currentPage === 'home' ? HomeBold : HomeLine, nav: '/' },
    { title: 'imgs list', icon: swrState?.currentPage === 'download' ? ListBold : ListLine, nav: '/download' },
  ];

  const iconListMap = useMemo(() => iconSeeds?.map((seed, idx) => (
    <div className="iconBox" onClick={() => navigate(seed.nav)} key={idx}>
      <img src={seed.icon} alt="" />
      <span>{seed.title}</span>
    </div>
  )), [iconSeeds]);

  return (
    <div className="sideNaviContainer">
      <div className="iconListBox">{iconListMap}</div>
    </div>
  );
};

export default SideNavi;
