import React from 'react';
import { useSWRState } from '../../fetcher/useSWRState';
import './BasicModal.scss';
import { useNavigate } from 'react-router-dom';

const BasicModal = () => {
  const navigate = useNavigate();
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const closeModal = () => {
    navigate('/');
    setSwrState({ ...swrState, modal: { ...swrState.modal, isOpenModal: false } });
  };

  return (
    <div className="modalContainer" onClick={closeModal}>
      <div className="modalBg" />
      <div className="modalBox">
        <div className="modalTitle">
          {swrState?.modal?.title}
        </div>
        <div className="modalContent">
          {swrState?.modal?.content}
        </div>
      </div>
    </div>
  );
};

export default BasicModal;
