import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import * as ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

export const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
axios.defaults.baseURL = baseUrl;
axios.defaults.withCredentials = false;

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <HelmetProvider>
      <App />
    </HelmetProvider>,
  );
} else {
  root.render(
    <HelmetProvider>
      <App />
    </HelmetProvider>,
  );
}

reportWebVitals();
