export const isProd = process.env.NODE_ENV === 'production';
export const isDev = process.env.NODE_ENV === 'development';
export const cl = (title, text) => !isProd && console.log(title, text);

/* random id 저장용 key 생성기 */
export const getRandomId = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = (today.getMonth() + 1).toString();
  let date = today.getDate().toString();
  let hour = today.getHours().toString();
  let min = today.getMinutes().toString();
  let sec = today.getSeconds().toString();
  if (month.length === 1) month = `0${month}`;
  if (date.length === 1) date = `0${date}`;
  if (hour.length === 1) hour = `0${hour}`;
  if (min.length === 1) min = `0${min}`;
  if (sec.length === 1) sec = `0${sec}`;

  const monthDate = month + date;
  const currentTime = hour + min + sec;
  const letterLength = 5;
  const randomIdProducer = Math.random().toString(36).substr(2, letterLength);
  const randomAuthKey = `${randomIdProducer}-${year}-${monthDate}-${currentTime}`;

  return randomAuthKey;
};

export const getCurDateTime = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = (today.getMonth() + 1).toString();
  let date = today.getDate().toString();
  let hour = today.getHours().toString();
  let min = today.getMinutes().toString();
  let sec = today.getSeconds().toString();
  if (month.length === 1) month = `0${month}`;
  if (date.length === 1) date = `0${date}`;
  if (hour.length === 1) hour = `0${hour}`;
  if (min.length === 1) min = `0${min}`;
  if (sec.length === 1) sec = `0${sec}`;

  const monthDate = month + date;
  const currentTime = hour + min + sec;
  const curDateTime = `${year}-${monthDate}-${currentTime}`;

  return curDateTime;
};

export const renameFile = (origin, newName) => new File([origin], newName, { type: origin.type, lastModified: origin.lastModified });
