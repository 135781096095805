import './styles/Common.scss';
import './styles/App.scss';
import React, { useEffect } from 'react';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import DownloadPage from './pages/DownloadPage';
import MetaTag from './components/MetaTag';
import { useTranslation } from 'react-i18next';
import i18n from './lang/i18n';

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('lang')) {
      if (localStorage.getItem('lang') === 'en') {
        i18n.changeLanguage('en');
      } else if (localStorage.getItem('lang') === 'ko') {
        i18n.changeLanguage('ko');
      } else if (localStorage.getItem('lang') === 'de') {
        i18n.changeLanguage('de');
      }
    }
    // i18n.changeLanguage('en');
    // console.log('i18n', i18n);
    // console.log('i18n.language', i18n.language);
    // console.log('t(\'Setting.csCenter\')', t('Setting.csCenter'));
    /* DEV, PROD 전용 언어 셋팅 */
    // isProd ? i18n.changeLanguage('ko') : i18n.changeLanguage(devLang);
  }, []);

  return (
    <BrowserRouter>
      <MetaTag />
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
