import useSWR from 'swr';

type SwrStateType = {
  user: {
    email: string;
  };
  modal:{
    title: string
    content: string
    isOpenModal: boolean
  }
  currentPage: string
  // eslint-disable-next-line @typescript-eslint/ban-types
};

let state: SwrStateType = {
  user: {
    email: '',
  },
  modal: {
    title: 'title',
    content: 'content',
    isOpenModal: false,
  },
  currentPage: null,
};

export const useSWRState = () => {
  const { data, mutate } = useSWR<SwrStateType>('state', () => state);

  return {
    data,
    mutate: (value: SwrStateType) => {
      state = value;
      return mutate();
    },
  };
};
