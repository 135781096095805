import React, { useEffect, useMemo, useState } from 'react';
import ReduceIcon from '../icons/down-arrow.png';
import '../styles/Download.scss';
import RemoveIcon from '../icons/cancel.png';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowIcon from '../icons/right-arrow.png';
import ExpandImgPopup from '../components/ExpandImgPopup';
import { useSWRState } from '../fetcher/useSWRState';
import CloseIcon from '../icons/close-line.png';
import SampleImg from '../icons/download-illustration.png';

const DownloadPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataState, setDataState] = useState(location?.state && location?.state[0]); // homepage 에서 convertedResState 값 전달
  const [fileState, setFileState] = useState(location?.state && location?.state[1]);
  const rawFile = location?.state && location.state[2];
  const [imageUrlState, setImageUrlState] = useState([]); // raw 파일용 state
  const [selectedIdxState, setSelectedIdxState] = useState(0);
  const [isOpenExpandState, setIsOpenExpandState] = useState<boolean>(false); // 하단의 이미지 클릭 시 확장된 이미지 나오도록 ~ 팝업 state
  const [expandSrcState, setExpandSrcState] = useState(''); // 확장된 이미지 src
  const { data: swrState, mutate: setSwrState } = useSWRState();

  const carculatePercent = (rawSize, convSize) => {
    const value = (convSize / rawSize) * 100; // 함수 결과값이 이상함 -> 나중에 수정해야 함!
    return Math.round((100 - value));
  };

  const removeBtnHandler = (e) => {
    const fileId = e.currentTarget.getAttribute('itemID');
    const fileIdx = e.currentTarget.getAttribute('datatype');
    const newFiles = [...fileState];
    newFiles.splice(fileIdx, 1);
    setFileState(newFiles);
    const newRawFiles = [...imageUrlState]; // raw 배열에서도 삭제
    newRawFiles.splice(fileIdx, 1);
    setImageUrlState(newRawFiles);
    const newDatas = dataState.filter((data) => !(fileId === data.filePath));
    setDataState(newDatas);
  };

  // 다운로드 버튼 클릭 시 zip 파일로 전체 저장
  const saveZip = (filename, urls) => {
    if (!urls) return;
    const zip = new JSZip();
    const folder = zip.folder('image2webp-images'); // folder name where all files will be placed in
    urls.forEach((url) => {
      const config = {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      // @ts-ignore
      const blobPromise = fetch(url, config).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });
      let name = url.substring(url.lastIndexOf('/') + 1);
      name = String(name.split('$')[2]);
      folder.file(name, blobPromise);
    });

    zip.generateAsync({ type: 'blob' })
      .then((blob) => saveAs(blob, filename))
      .finally(() => {
        // todo: 다운로드 완료가 되는 시점에(파일 저장 완료) 광고라던지 화면 전황이 필요!
        // navigate('/');
      });
  };

  /* 확장된 이미지 보여주는 팝업 ~ 핸들러 */
  const openExpandImgHandler = (e) => {
    setExpandSrcState(e.currentTarget.src);
    setIsOpenExpandState(!isOpenExpandState);
  };

  /* 배열의 각 data 클릭 시 이벤트 */
  const dataClick = (e) => {
    const idx = Number(e.currentTarget.getAttribute('itemid'));
    setSelectedIdxState(idx);
    (document.querySelector('#compareImgContainer') as HTMLDivElement).style.display = 'flex';
  };

  const rawFileImgSetting = async () => {
    const imgArr = [];
    let file;
    for (let i = 0; i < rawFile.length; i++) {
      file = rawFile[i];
      if (!(file instanceof Blob)) {
        console.error('파일이 Blob 형식이 아닙니다.');
        return;
      }
      const fileReader = new FileReader();
      fileReader.onload = () => {
        imgArr[i] = fileReader.result;
        setImageUrlState([...imgArr]); // 미리보기를 위한 *임시 url* (Blob 형태)
      };
      fileReader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setSwrState({ ...swrState, currentPage: 'download' });
  }, []);

  useEffect(() => {
    /* 다운로드할 데이터가 없으면 처음 화면으로 돌아감 */
    if (dataState?.length === 0 || !dataState?.length) {
      setSwrState({
        ...swrState,
        modal: {
          title: 'Image file needs',
          // content: '업로드 제한 갯수는 최대 20개까지 입니다.',
          content: 'Please upload image files!',
          isOpenModal: true,
        },
      });
      navigate('/');
    }
  }, [dataState]);

  // raw file transfer test
  useEffect(() => {
    if (rawFile?.length === 0) return;
    rawFileImgSetting();
  }, [rawFile]);

  return (
    <div className="downloadContainer">
      <div className="downloadContentBox">
        {/* 이미지 리스트 */}
        <div className="downloadContentContainer">
          {dataState?.map((el, idx) => (
            <div className="downloadContent" key={idx}>
              <img className="listImg" alt="noImg" src={fileState[idx]} />
              <div className="blackBg" />
              {/* close 아이콘 */}
              <div className="removeDiv">
                <button className="removeButton" onClick={removeBtnHandler} itemID={el.filePath} datatype={idx}>
                  <img alt="remove" className="removeIcon" src={CloseIcon} />
                </button>
              </div>
              <div className="downListContainer" onClick={dataClick} itemID={idx}>

                {/* image name */}
                <span className="imgName">{`${el.filePath.split('$')[2]}`}</span>

                {/* before, reduce */}
                <div className="dataInfoContainer">
                  <div className="dataInfoBox">
                    {/* MB convert to KB */}
                    <span className="dataTextSpan">{`${(el.beforeSize) * 1024}KB`}</span>
                    <span className="textSpan">before</span>
                  </div>

                  <div className="dataInfoBox">
                    <span className="dataTextSpan">{`${carculatePercent(el.beforeSize, el.afterSize)}%⬇️`}</span>
                    <span className="textSpan">reduced</span>
                  </div>
                </div>

                <div className="dataInfoContainer">
                  <div className="dataInfoBox">
                    {/* MB convert to KB */}
                    <span className="dataTextSpan">{`${(el.afterSize) * 1024}KB`}</span>
                    <span className="textSpan">webp</span>
                  </div>
                </div>

              </div>
            </div>
          ))}
        </div>

        {/* 하단 before after 이미지 */}
        <div
          id="compareImgContainer"
          className="compareImgContainer"
          onClick={() => {
            (document.querySelector('#compareImgContainer') as HTMLDivElement).style.display = 'none';
          }}
        >
          <div className="compareImgBox">
            {/* 기존 */}
            <div className="displayRawImgDiv">
              {/* 변환 전 raw 이미지 출력 */}
              <span>before</span>
              {imageUrlState && imageUrlState.map((url, idx) => {
                if (idx === selectedIdxState) {
                  return (
                    <img
                      key={idx}
                      alt=""
                      src={url}
                      onClick={openExpandImgHandler}
                      id="rawImage"
                      className="displayRawImg"
                    />
                  );
                }
                return null;
              })}
            </div>
            <div className="displayArrowIconDiv">
              <img className="displayArrowIcon" alt="img" src={ArrowIcon} />
            </div>
            <div className="displayConvImgDiv">
              {/* 변환 후 converted 이미지 출력 */}
              <span>webp</span>
              {
                fileState && fileState.map((url, idx) => {
                  if (idx === selectedIdxState) {
                    return (
                      <img
                        key={idx}
                        src={url}
                        alt=""
                        onClick={openExpandImgHandler}
                        className="displayConvImg"
                      />
                    );
                  }
                  return null;
                })
              }
            </div>
          </div>

        </div>

        {/* Home & Download All  */}
        <div className="bottomBtns">
          <button className="downloadImgBtn" onClick={() => navigate('/')}>
            Home
          </button>
          <button className="downloadImgBtn" onClick={() => saveZip('image2webp-images.zip', fileState)}>
            Download All
          </button>
        </div>

        {isOpenExpandState && (
          <ExpandImgPopup setIsOpenExpandState={setIsOpenExpandState} expandSrcState={expandSrcState} />
        )}
      </div>
    </div>
  );
};

export default DownloadPage;
