import React from 'react';
import IconCancle from '../icons/cancel.png';

const Intro = ({ openCloseModal }) => {
  const closeModal = () => {
    (document.querySelector('#contentModal') as HTMLDivElement).style.display = 'none';
  };
  return (
    <div className="contentBox">
      <div className="contentCloseBox">
        <span />
        <img src={IconCancle} alt="" width={30} height={30} datatype="intro" onClick={openCloseModal} />
      </div>
      <h1>Have you ever experienced a slow page loading after creating and deploying a web app?</h1>
      <br />
      <br />
      Optimizing performance is crucial to improving the user experience of web apps. If it takes more than three seconds for a user to see something on the homepage, they will feel inconvenienced. Additionally, if an application doesn&apos;t respond immediately to a user&apos;s clicks, they will feel frustrated.

      <br />
      <br />
      Therefore, performance optimization is essential, and you can experience faster speeds by minimizing the image size used on various pages. In fact, when I first started web development, I thought that displaying high-quality images would make the page look clear and beautiful.

      <br />
      <br />
      In reality, using high-quality images didn&apos;t cause any problems, at least until I started developing the page. Of course, there were no issues when rendering locally on my computer. However, problems arise when deploying and hosting on a server. Servers are less powerful than a developer&apos;s computer, and if the resources on the page are not optimized, it will naturally slow down.

      <br />
      <br />
      At webpactory, we make image size optimization as easy and simple as possible among many methods. It&apos;s effortless. Just drag or select (step 1) the images you use for web development and download (step 2). It&apos;s that simple.
      <br />
      <br />
    </div>
  );
};

export default Intro;
