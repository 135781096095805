import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTag = () => {
  const ogImageLink = '';
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="theme-color" content="#ffffff" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="[image2webp.com] convert images to webp" />
      <meta
        property="og:description"
        content="This is an application that converts image files such as PNG and JPG to the WebP format. WebP maintains decent image quality while significantly reducing file size compared to other image formats. If website optimization is needed, consider converting your current PNG, JPG, and other image files to WebP!"
      />
      <meta property="og:image" content="https://image2webp.com/images/title_image.jpg" />
      <meta property="og:image" content={ogImageLink} />
      <meta
        property="og:image:alt"
        content="This is an application that converts image files such as PNG and JPG to the WebP format. WebP maintains decent image quality while significantly reducing file size compared to other image formats. If website optimization is needed, consider converting your current PNG, JPG, and other image files to WebP!"
      />
      <meta property="og:url" content="https://image2webp.com" />
      <meta property="og:site_name" content="[image2webp.com] convert images to webp" />
      <meta name="google-site-verification" content="" />
      <meta name="naver-site-verification" content="" />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="image converter, webp converter, img, image, converter, webp, image size reduce"
      />
      <meta
        name="description"
        content="This is an application that converts image files such as PNG and JPG to the WebP format. WebP maintains decent image quality while significantly reducing file size compared to other image formats. If website optimization is needed, consider converting your current PNG, JPG, and other image files to WebP!"
        data-react-helmet="true"
      />
      <meta name="google-adsense-account" content="ca-pub-6495523701848301" />
      <link rel="icon" href="./favicon.ico" />
      <link rel="apple-touch-icon" href="./logo192.png" />
      <link rel="manifest" href="./manifest.json" />
      <title>Image to Webp</title>
    </Helmet>
  );
};

export default MetaTag;
