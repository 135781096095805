import React, {
  useEffect, useState, useRef, ChangeEvent, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/pages/Home.scss';
import TypeIt from 'typeit-react';
import DownloadIllust from '../icons/download-illustration.png';
import axios from 'axios';
import { cl, getCurDateTime, renameFile } from '../utils/utilities';
import { SquareLoader } from 'react-spinners';
import imageCompression from 'browser-image-compression';
import BasicModal from '../components/BasicModal/BasicModal';
import { useSWRState } from '../fetcher/useSWRState';
import heic2any from 'heic2any';
import { baseUrl } from '../index';

/*
* 50mb to bytes = 52,428,800
* 20mb to bytes = 20,971,520
* */

const HomePage = () => {
  const navigate = useNavigate();
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const imageBaseUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api/images/get-one/`;
  const [thumbnailState, setThumbnailState] = useState<FileList>();
  const [resizedThumbState, setResizedThumbState] = useState<FileList>();
  const [loadingState, setLoadingState] = useState(false);
  const [zipFileState, setZipFileState] = useState<Array<string>>();
  const [convertedResState, setConvertedResState] = useState<Array<{
    beforeSize: number
    afterSize: number
    filePath: string
  }>>();
  const limitUploadCnt = 20;
  const limitUploadSize = 20 * 1000; // 20MB
  // const limitUploadSize = 20 * 10000;
  // const maxWidthHeight = 1200; // width 1200, height 1200
  const maxWidthHeight = 1200000; // (이미지의 최대 너비나 높이)
  // const maxSizeAsMb = 1;
  const maxSizeAsMb = 20; // (최대 파일 크기 설정)

  const source = useRef<any>(null);
  const controller = new AbortController();

  const [isDropFileState, setIsDropFileState] = useState<boolean>(false);
  // 드래그 이벤트를 감지하는 ref 참조변수(label 태그에 넣을거임)
  const dragRef = useRef<HTMLLabelElement | null>(null);
  // 드래그 중 일 때와 아닐때를 구분하기 위한 state(추후 스타일 변경이라든지에 활용 가능?)
  const [isDraggingState, setIsDraggingState] = useState<boolean>(false);
  const typeItDestroyVer = (text) => (
    <TypeIt
      getBeforeInit={(instance) => {
        instance.type(text);
        setTimeout(() => {
          document.querySelectorAll('.ti-cursor').forEach((ele) => {
            (ele as HTMLSpanElement).style.display = 'none';
          });
        }, 3000);
        return instance;
      }}
    />
  );
  const typeItDestroyVerLoop = (text) => (
    <TypeIt
      getBeforeInit={(instance) => {
        instance.options({ lifeLike: false, nextStringDelay: 1000, loop: true }).type(text);
        setTimeout(() => {
          document.querySelectorAll('.ti-cursor').forEach((ele) => {
            (ele as HTMLSpanElement).style.display = 'none';
          });
        }, 3000);
        return instance;
      }}
    />
  );

  /* 드래그 앤 드랍 및 파일 첨부 버튼을 눌러서 선택한 파일을 관리할 함수 */
  const onChangeFiles = useCallback((e: ChangeEvent<HTMLInputElement> | any) => {
    let files : FileList;
    if (e.type === 'drop') { // 드래그 앤 드롭 시
      files = e.dataTransfer.files;
    } else {
      files = e.target.files; // 파일 업로드 눌러서 진행할 때
    }
    if (files.length > limitUploadCnt) {
      setSwrState({
        ...swrState,
        modal: { title: 'ERROR OCCURED😱', content: 'The upload limit is a maximum of 20 files.', isOpenModal: true },
      });
      return;
    }
    const fileArray = Array.from(files);
    const limitSize = fileArray.map((obj) => Math.ceil((obj.size / 1024))).reduce((acc, cur) => acc + cur);
    if (limitSize > limitUploadSize) {
      setSwrState({
        ...swrState,
        modal: { title: 'ERROR OCCURED😱', content: 'The upload limit is up to 20MB.', isOpenModal: true },
      });
      return;
    }

    setThumbnailState(files);
    setIsDropFileState(true); // 드랍 하거나 파일 업로드 완료 시
  }, [thumbnailState]);

  // 드래그 앤 드롭 이벤트 구현 시 총 4개의 이벤트 처리를 해줘야 함
  // DragEnter, DragLeave, DragOver, Drag
  // 컴포넌트가 언마운트 되었을 때 등록된 이벤트들을 지워줘야 함
  // 마운트 / 언마운트 시 이벤트 처리를 해주어야 브라우저 이벤트에 방해되지 않음
  const handleDragIn = useCallback((e: DragEvent) => {
    e.preventDefault(); // 브라우저 이벤트 방지
    e.stopPropagation(); // 부모 이벤트 방지
  }, []);

  const handleDragOut = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingState(false); // Drag Out 했을 때 상태 변경
  }, []);

  const handleDragOver = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer!.files) setIsDraggingState(true);
  }, []);

  const handleDrop = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChangeFiles(e);
    setIsDraggingState(false);
  }, [onChangeFiles]);

  /* 위의 4개의 이벤트에 Listener를 등록(마운트 시) */
  const initDragEvents = useCallback(() => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener('dragenter', handleDragIn);
      dragRef.current.addEventListener('dragleave', handleDragOut);
      dragRef.current.addEventListener('dragover', handleDragOver);
      dragRef.current.addEventListener('drop', handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  /* 위의 4개의 이벤트의 Listener를 삭제(언마운트 시) */
  const resetDragEvents = useCallback(() => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener('dragenter', handleDragIn);
      dragRef.current.removeEventListener('dragleave', handleDragOut);
      dragRef.current.removeEventListener('dragover', handleDragOver);
      dragRef.current.removeEventListener('drop', handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const fileNameFilter = (fileName) => {
    const removeSpacedName = fileName.split(' ').join('');
    const splitedFileName = removeSpacedName.split('.');
    splitedFileName.pop();
    const newFileName = splitedFileName.join('');
    return newFileName;
  };

  const handleSetThumbnail = (e) => {
    const target = e.currentTarget.files;
    const files = [...target];
    if (files.length > limitUploadCnt) {
      setSwrState({
        ...swrState,
        modal: { title: 'ERROR OCCURED😱', content: 'The upload limit is a maximum of 20 files.', isOpenModal: true },
      });
      return;
    }
    const limitSize = files.map((obj) => Math.ceil((obj.size / 1024))).reduce((acc, cur) => acc + cur);
    if (limitSize > limitUploadSize) {
      setSwrState({
        ...swrState,
        modal: { title: 'ERROR OCCURED😱', content: 'The upload limit is up to 20MB.', isOpenModal: true },
      });
      return;
    }
    setThumbnailState(e.target.files);
  };

  const handleFileOnChange = async (file) => {
    const options = { maxSizeMB: maxSizeAsMb, maxWidthOrHeight: maxWidthHeight, useWebWorker: true };

    try {
      /* 이미지 용량 축소 */
      const compressedFile = await imageCompression(file, options);
      const resultFile = new File([compressedFile], compressedFile.name, {
        type: compressedFile.type,
      });
      return resultFile;
    } catch (error) {
      /* heic 파일이거나 imageCompression이 안먹히는 파일은 heic2any로 변환 후 작업해준다. */
      const resultBlob = await heic2any({ blob: file, toType: 'image/webp' });
      // @ts-ignore
      file = new File([resultBlob], `${file.name.split('.')[0]}.webp`, {
        type: 'image/webp',
        lastModified: new Date().getTime(),
      });
      const compressedFile = await imageCompression(file, options);
      const resultFile = new File([compressedFile], compressedFile.name, {
        type: compressedFile.type,
      });
      return resultFile;
      console.log(error);
    }
  };

  const uploadThumbnail = async () => {
    setLoadingState(true);
    const thumbnailFormData = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const filesArr = [...thumbnailState];
    const resizedFiles = [];
    const renamedFiles = [];
    filesArr.reduce((acc, cur) => {
      const { name } = cur;
      const nameSplit = name.split('.');
      nameSplit.pop();
      const nameKey = String(nameSplit.join('.'));
      if (acc[nameKey]) {
        acc[nameKey] += 1;
      } else {
        acc[nameKey] = 1;
      }

      let file = cur;
      let originName = String(acc[nameKey] - 1 !== 0 ? `${nameKey}_${acc[nameKey] - 1}.jpg` : `${nameKey}.jpg`);
      const hasSpecialChars = /[!@#$%^&*(),?":{}|<>[\]']/.test(originName);
      /* 특수문자 제거 */
      if (hasSpecialChars) {
        originName = originName.replace(/[!@#$%^&*(),?":{}|<>[\]']/g, '');
      }
      file = new File([file], originName, {
        type: file.type,
        lastModified: file.lastModified,
      });
      renamedFiles.push(file);

      return acc;
    }, {});
    for (const file of renamedFiles) {
      const newFile = await handleFileOnChange(file);
      resizedFiles.push(newFile);
    }
    console.log('resizedFiles', resizedFiles);
    const filesMap = resizedFiles.map((file) => renameFile(file, `${fileNameFilter(file.name)}.jpg`));
    filesMap.forEach((file) => thumbnailFormData.append('files', file));
    // @ts-ignore
    // setThumbnailState([...resizedFiles]);
    setResizedThumbState([...resizedFiles]);

    const serverAxios = axios.create({
      baseURL: baseUrl,
      proxy: { protocol: 'http', host: baseUrl.split('//')[1], port: 5015 },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    try {
      if (source.current !== null && !source.current.aborted) { // already exists request
        console.log('request canceled');
        source.current.abort();
      }
      source.current = controller;
      if (source.current !== null) {
        serverAxios.post('/api/files/upload-thumbnails', thumbnailFormData, config)
          .then((res) => {
            setConvertedResState(res?.data);
            setZipFileState(res.data.map((obj) => `${imageBaseUrl}${obj?.filePath}`));
          });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    setSwrState({ ...swrState, currentPage: 'home' });
    // navigate('/download');
  }, []);

  useEffect(() => {
    if (thumbnailState) uploadThumbnail();
  }, [thumbnailState]);

  // 드래그 앤 드랍용 useEffect
  useEffect(() => {
    initDragEvents();
    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  useEffect(() => {
    if (zipFileState) navigate('/download', { state: [convertedResState, zipFileState, resizedThumbState] });
  }, [zipFileState]);

  return (
    <>
      {swrState?.modal?.isOpenModal && <BasicModal />}
      <div className="HomeContainer">
        <div className="homeContentBox">
          <div className="homeContent">
            {loadingState
              ? (
                <div className="uploadLoadingBox">
                  <SquareLoader color="#5DCBFC" />
                  <br />
                  {typeItDestroyVerLoop('imgs are converting now...')}
                </div>
              )
              : (
                <>
                  {/* drag & drop test */}
                  <input
                    type="file"
                    id="fileDragDrop"
                    style={{ display: 'none' }}
                    multiple
                    onChange={onChangeFiles}
                    accept="image/*"
                    name="file"
                  />
                  {/* 드래그 앤 드랍 테스트 작업 중 */}
                  <label className="innerDragDropLabel" htmlFor="fileDragDrop" ref={dragRef}>
                    <div className="homeContentImg">
                      {/* 이미지 넣을 자리 */}
                      <img className="sampleUpload" alt="" src={DownloadIllust} />
                    </div>
                    <div className="homeTextContent">{typeItDestroyVer('Drop or upload your images here!')}</div>
                  </label>
                </>
              )}
          </div>
          {!loadingState && (
            <div className="contentBtnBox">
              <input
                name="file"
                type="file"
                accept="image/*"
                multiple
                onChange={handleSetThumbnail}
                id="uploadInput"
              />
              <label htmlFor="uploadInput" className="uploadLabel">Choose Files</label>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HomePage;
