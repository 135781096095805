import React from 'react';
import '../styles/components/ContentModal.scss';
import IconCancle from '../icons/cancel.png';

const ContentModal = ({ content }) => (
  <div id="contentModal" className="contentModal">
    {content}
    <div className="contentBg" />
  </div>
);

export default ContentModal;
