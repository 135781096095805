import i18n from 'i18next';
// @ts-ignore
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
// @ts-ignore
import { LangResources } from '../../@types/langType/langResources';
import { En } from './locales/En';
import { Ko } from './locales/Ko';
import { De } from './locales/De';

const resources:LangResources = {
  en: {
    translation: En,
  },
  ko: {
    translation: Ko,
  },
  de: {
    translation: De,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ko',
    fallbackLng: 'ko',
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
