import React from 'react';
import IconCancle from '../icons/cancel.png';

const Manual = ({ openCloseModal }) => {
  const closeModal = () => {
    (document.querySelector('#contentModal') as HTMLDivElement).style.display = 'none';
  };
  return (
    <div className="contentBox">
      <div className="contentCloseBox">
        <span>User Manual</span>
        <img src={IconCancle} alt="" width={30} height={30} onClick={openCloseModal} datatype="manual" />
      </div>

      <h3>1. Select the file to upload:</h3>
      Choose the image file you want to convert to WEBP and upload it. Two methods are available for uploading files.
      <br />
      First, you can click the &quot;upload&quot; button in the middle of the screen to upload a file. When you click the &quot;upload&quot; button, your local file manager will open, allowing you to browse for the image file you want to convert.
      <br />
      Second method is drag and drop. Select the image file you want to convert to WEBP from your local computer and then drag and drop it into the space in the center of the page that is written &quot;Please Drop or Upload your imgs here&quot;.
      <br />
      <br />
      <h3>2. Convert image files to WEBP format:</h3>
      After selecting or drag and dropping the files, the selected image files will be automatically converted to WEBP format. This process takes a few seconds. Please wait for a moment.
      <br />
      <br />
      <h3>3. Check the converted image files in WEBP format:</h3>
      After the process to webp format is completed, the download page will be displayed. if you click the thumbnail of each converted image, before and after images will be displayed at the bottom of the screen. Clicking each output image at the bottom will allow you to view it in higher resolution. You can also view the original and converted image file sizes in the converted image file list, and you can remove it from the download list by clicking the &quot;x&quot; icon on the right. If you remove all image files from the list, you will be automatically redirected to the initial page.
      <br />
      <br />
      <h3>4. Download the converted files:</h3>
      Click the &quot;DOWNLOAD ALL&quot; button at the bottom of the page to download the converted image files. The converted image files will be automatically compressed by zip file and downloaded. You can access the converted WEBP image files by extracting the downloaded zip file
      <br />
      <br />
    </div>
  );
};

export default Manual;
