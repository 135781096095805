import React, { useEffect, useRef } from 'react';
import '../styles/ExpandImgPopup.scss';

const ExpandImgPopup = ({ setIsOpenExpandState, expandSrcState }) => {
  console.log('이미지 클릭 시 확장되는 팝업');

  // 팝업 DOM 선택용 useRef
  const imgPopupRef = useRef<HTMLDivElement>(null);
  // 팝업 영역 밖 선택 시 팝업창 끄기
  useEffect(() => {
    console.log('imgPopupRef', imgPopupRef.current);
    const popupOnOffHandler = (event) => {
      // console.log(newCamRegisterPopupRef.current.contains(event.target));
      // mousedown 이벤트가 발생한 영역이 모달창이 아닐때 모달창 제거
      if (
        imgPopupRef.current
          && !imgPopupRef.current.contains(event.target) // 밖을 클릭할 때만 ~
      // imgPopupRef.current.contains(event.target)
      //   || !imgPopupRef.current.contains(event.target) // 어디든 클릭하면 ~
      ) {
        // TODO: 현재 여기 작업 중! test
        // document.getElementById('newCamContainer').style.background = 'green';
        setIsOpenExpandState(false);
      }
    };
    // 이벤트 핸들러 등록
    document.addEventListener('mousedown', popupOnOffHandler);
    // 모달(팝업) 특성 상 컴포넌트가 unmount되는 주기가 많으므로 return문을 통해 이벤트 remove가 필요!
    return () => {
      // 이벤트 핸들러 해제
      document.removeEventListener('mousedown', popupOnOffHandler);
    };
  });

  useEffect(() => {
    console.log('imgPopupRef가 변경되었습니다');
    // test
    const targetBox = document.getElementById('newCamBox');
    // 영역 밖으로 마우스가 나가면 커서 포인터로 변경(이벤트 버블링 안되게~ : mouseleave)
    targetBox?.addEventListener('mouseleave', (e) => {
      console.log('현재 영역 밖입니다');
      document.getElementById('newCamContainer').style.cursor = 'pointer';
    });
    // 영역 안으로 마우스가 들어가면 다시 커서 포인트 기본으로 변경(이벤트 버블링 안되게~ : mouseenter)
    targetBox?.addEventListener('mouseenter', (e) => {
      console.log('현재 영역 안입니다');
      document.getElementById('newCamContainer').style.removeProperty('cursor');
    });
  }, [imgPopupRef]);

  // html 태그 리턴 시 작성 예정...
  return (
  // <div>
  //   hmtl 태그 작성해야 함
  // </div>
  // TODO: 작업중: 우리꺼에 맞게 수정 작업해야 함
    <div className="newCamContainer" id="newCamContainer">
      <div className="newCamBox" ref={imgPopupRef} id="newCamBox">
        {/* <div className="newCamTitle">새 카메라 등록</div> */}
        <div className="newCamContentBox">
          <div className="newCamContent">
            {/* 메인 컨텐츠 들어가는 곳 */}
            <img alt="no img" src={expandSrcState} />
          </div>
          <div className="newCamBtnBox">
            <div className="newCamButtons">
              {/* <button */}
              {/*  className="btnR normalGhost" */}
              {/* > */}
              {/*  취소 */}
              {/* </button> */}
              <button
                className="btnR defaultPrimary"
                onClick={() => { setIsOpenExpandState(false); }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpandImgPopup;
