import React, { useEffect, useState } from 'react';
import SideNavi from './SideNavi';
import '../styles/Layout.scss';
import { useSWRState } from '../fetcher/useSWRState';
import Api from '../api/Api';
import { useMediaQuery } from '../hooks/useMediaQuery';
import GoogleAdvertise from './GoogleAdvertise';
import GrayBlur from '../icons/gray-blur.png';
import PinkBlur from '../icons/pink-blur.png';
import ImgIllust from '../icons/img-illustration.png';
import BrowserIllust from '../icons/browser-illustration.png';
import ContentModal from './ContentModal';
import Manual from './Manual';
import Intro from './Intro';

const Layout = ({ children }) => {
  const { data: swrState, mutate: setSwrState } = useSWRState();
  const isPageWide = useMediaQuery('(min-width: 916px)');
  const [isOpenModalState, setIsOpenModalState] = useState({
    intro: false,
    manual: false,
  });
  const handleMenuHide = () => {
    if (!isPageWide) {
      const menuBar = (document.querySelector('.sideNaviBox') as HTMLDivElement);
      menuBar.style.display = 'none';
    }
  };

  const openCloseModal = (e) => {
    const target = e.currentTarget;
    const dType = target.getAttribute('datatype');
    console.log('dType', dType);
    setIsOpenModalState((prevState) => ({ ...prevState, [dType]: false }));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    window.addEventListener('scroll', () => {
      // 스크롤을 할 때마다 로그로 현재 스크롤의 위치가 찍혀나온다.
      console.log(window.scrollX, window.scrollY);
    });
  }, []);
  // <div className="leftAdBox" />

  return (
    <div className="layout">
      {isOpenModalState.intro && <ContentModal content={(<Intro openCloseModal={openCloseModal} />)} />}
      {isOpenModalState.manual && <ContentModal content={(<Manual openCloseModal={openCloseModal} />)} />}
      <section className="layoutTitleSection">
        <div className="title">IMAGE 2 WEBP</div>
        <div className="titleBar" />
        <div className="titleMent">Convert Images to webp. for free!</div>
      </section>
      <section className="layoutTopSection">
        {/* <GoogleAdvertise */}
        {/*  client="ca-pub-6495523701848301" */}
        {/*  slot="3811991570" */}
        {/*  format="auto" */}
        {/*  responsive="true" */}
        {/* /> */}

        <div className="layoutContainer" onScroll={handleMenuHide}>
          <SideNavi />
          <div className="layoutRightContainer">
            {children}
          </div>
        </div>

        {/* <GoogleAdvertise */}
        {/*  client="ca-pub-6495523701848301" */}
        {/*  slot="5424186647" */}
        {/*  format="auto" */}
        {/*  responsive="true" */}
        {/* /> */}
      </section>

      {/* 사진 업로드 창 아래 글 */}
      {/* <section className="layoutBottomSection"> */}
      {/*  <div className="contentMoreBtns"> */}
      {/*    <button onClick={() => setIsOpenModalState((prevState) => ({ ...prevState, intro: true }))}>Intro</button> */}
      {/*    <button onClick={() => setIsOpenModalState((prevState) => ({ ...prevState, manual: true }))}>Manual</button> */}
      {/*  </div> */}
      {/*  <div className="contentAboutLimit"> */}
      {/*    This free online tool converts your PNG images to JPEG format, applying proper compression methods. Unlike other services, this tool does not ask for your email address, offers mass conversion and allows files */}
      {/*    {' '} */}
      {/*    <span>up to 50 MB.</span> */}
      {/*  </div> */}
      {/*  <div className="contentAbout"> */}
      {/*    <span>Why should we use the WebP?</span> */}
      {/*    WebP format reduces file size while maintaining image quality by providing high compression. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    This is made possible by WebP`s superior compression algorithm, which predicts the colors in an image and removes unnecessary data based on this prediction. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    As a result, WebP files are smaller than both JPEG and PNG files, which is particularly useful on mobile devices. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    on limited bandwidth on mobile devices, smaller file sizes lead to faster loading times and less data consumption. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    also, WebP supports transparency, this feature was previously only available in PNG files. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    WebP`s transparency feature supports both opaque and transparent images, */}
      {/*    it is very useful for removing image backgrounds or adding semi-transparent logos. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    As an image file format, WebP is extremely useful for web developers and designers, */}
      {/*    it`s help to improve website performance by speeding up page load times and conserving bandwidth. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    In addition, WebP is very useful on mobile devices that lead to faster loading times and less data consumption at limited bandwidth. */}
      {/*    For these reasons, WebP is a very useful image file format for providing a better user experience. */}
      {/*  </div> */}

      {/*  <div className="contentAboutStrength"> */}
      {/*    <img src={PinkBlur} alt="" className="pinkBlur" /> */}
      {/*    <img src={GrayBlur} alt="" className="grayBlur" /> */}
      {/*    <div className="strengthBox"> */}
      {/*      <div className="strengthBoxTop"> */}
      {/*        <span>Quick Image Upload and Download</span> */}
      {/*        <img src={ImgIllust} alt="" width={140} height={120} /> */}
      {/*      </div> */}
      {/*      <div className="strengthBoxBottom"> */}
      {/*        Our website allows for easy uploading of multiple image files through drag and drop or upload buttons. */}
      {/*        <br /> */}
      {/*        <br /> */}
      {/*        It also allows users to quickly download images converted to the WebP format. */}
      {/*        By providing fast image upload and download, users can easily obtain converted image files */}
      {/*      </div> */}
      {/*    </div> */}
      {/*    <div className="strengthBox"> */}
      {/*      <div className="strengthBoxTop"> */}
      {/*        <span>Significantly Reduced Image File Sizes</span> */}
      {/*        <img src={BrowserIllust} alt="" width={140} height={140} /> */}
      {/*      </div> */}
      {/*      <div className="strengthBoxBottom"> */}
      {/*        Our website`s function reduces the file size of images, resulting in faster loading times on web pages. */}
      {/*        <br /> */}
      {/*        <br /> */}
      {/*        This improves website performance, reduces bandwidth usage, */}
      {/*        and enables users to feel faster page loading times and a better user experience. */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </div> */}

      {/*  <div className="pinkBar" /> */}

      {/*  <div className="contentAboutSafe"> */}
      {/*    <span>Is it safe to convert files with Webpactory?</span> */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    Our website provides a service that converts user-uploaded image files to WebP format. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    This service is convenient to use without requiring user registration, and we do not store the files uploaded by users on our server. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    This is very useful for protecting user privacy. Additionally, WebP has high compression rates for image files, which is very useful for reducing file sizes and improving website loading speed and bandwidth usage. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    But, we do not process the content of files uploaded by users separately in our service, so the user is responsible for the security of the uploaded files. */}
      {/*    <br /> */}
      {/*    <br /> */}
      {/*    Using the HTTPS protocol in our website, which encrypts communication between the user and the server. */}
      {/*    <br /> */}
      {/*    This is very useful for protecting user privacy and data. */}
      {/*  </div> */}

      {/* <div className="producer">made by intelloper</div> */}
      {/* </section> */}
    </div>
  );
};

export default Layout;
